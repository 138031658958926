import React from "react";
import FooterContact from "./FooterContact";
import FooterCopyRight from "./FooterCopyRight";
import FooterNavigation from "./FooterNavigation";
import FooterStaticLinks from "./FooterStaticLinks";
import FooterAdsContact from "components/homepage/Ads-Page/footerAds";

export default function Footer({whatsappBot}) {
  return (
    <React.Fragment>
      <FooterNavigation />
      <FooterAdsContact />
      <FooterCopyRight whatsappBot={whatsappBot } />
    </React.Fragment>
  );
}
