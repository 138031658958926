import ATA from "assets/images/new-homepage-assets/accreditation icons/ata.png";
import CLUTCH from "assets/images/new-homepage-assets/accreditation icons/clutch.png";
import ISO from "assets/images/new-homepage-assets/accreditation icons/iso.png";
import USCIS from "assets/images/new-homepage-assets/accreditation icons/uscis.svg";
import ISO3 from "assets/new-assets/ISO_17100.svg";
import ISO2 from "assets/new-assets/ISO_2007.svg";
import LogoSvg from "assets/svg/logo";
import React from "react";


export default function FooterNavigation() {
  return (
    <>
      <div className="pt-24  bg-basecol text-gray px-10 md:pl-10 xl:pl-0">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2  gap-2 lg:place-content-center md:p-0 sm:p-7">
            <div className="font-opensans mb-10 lg:mb-0 md:mb-0">
              <div className="flex pb-12 items-center">
              <a href="https://www.tomedes.com/">
                <LogoSvg />
              </a>
              </div>
              <div className="flex items-center justify-center flex-col">
                <h3 className="text-sm font-opensans font-normal leading-[28px] text-footertext">
                  Tomedes Translation Services is committed to broadening the
                  horizons of individuals and businesses in the United States
                  and internationally, through the effective use of professional
                  translation services.{" "}
                </h3>
                <h3 className="text-sm font-opensans font-normal leading-[28px] mt-8 text-footertext">
                  Our translation company provides fast and accurate translation
                  services in more than 120 languages and 950+ language pairs
                  and is available 24/7 via email, phone or chat.
                </h3>
              </div>
            </div>

            <div className="mb-10 lg:mb-24 md:pl-7 ml-0 lg:ml-16">
              <p className="text-[18px] font-secondary font-semibold uppercase">
                Ensured By Certifying Institutional Bodies
              </p>
              <div className="grid grid-cols-3 items-center gap-y-10 gap-x-5 pt-14">
                <img src="https://www.tomedes.com/static/9001-da0972656b253aad9080ecef8f1922e1.webp?width=0" alt="" className="w-[84px] h-[84px]" />
                <img src="https://www.tomedes.com/static/18587-a1c764d0ddf5cec6cfbeef6f79744ba9.webp?width=0" alt="" className="w-[84px] h-[84px]" />
                <img src="https://www.tomedes.com/static/17100-0ff9c3017e6dba8263d7287b8d9e2c9b.webp?width=0" alt="" className="w-[84px] h-[84px]" />
                <img src={ATA} alt="" className="w-[100px] h-[38px]" />
                <img src={CLUTCH} alt="" className="w-[80px] h-[80px]" />
                <img src={USCIS} alt="" className="w-[112px] h-[36px]" />
              </div>
            </div>
          </div>
        </div>
      </div>    
    </>
  );
}
