import React from "react";
import Location from "assets/images/footer-images/address.svg";
import Phone from "assets/images/footer-images/call.svg";
import Email from "assets/images/footer-images/mail.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube,faInstagram, faTiktok} from "@fortawesome/free-brands-svg-icons";

export default function FooterAdsContact() {
  return (
    <>
      <footer>
        <div className=" pb-10 bg-basecol text-gray px-10 md:pl-10 xl:pl-0">
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 justify-items-start md:p-0 sm:p-7 md:pb-0">
              <div className="font-opensans mb-10 lg:msb-0 md:mb-0">
                <div className="flex items-start">
                  <img src={Location} alt="" className="mr-5 w-4" />
                  <div className="">
                    <p className="text-sm font-opensans font-normal text-[#C5C5C5] mb-[6px]">Tomedes Ltd - USA 9450 SW </p>
                    <p className="text-sm font-opensans font-normal text-[#C5C5C5]">Gemini Dr #34540, Beaverton, Oregon</p>
                  </div>
                </div>
              </div>
              <div className="mb-10 flex  items-start">
                <img src={Phone} alt="" className="mr-5 w-4" />
                <div className="">
                  <p className="text-sm font-opensans font-normal text-[#C5C5C5] mb-[6px]">US: +1 985 239 0142</p>
                  <p className="text-sm font-opensans font-normal text-[#C5C5C5]">UK: +44 1615 096140 </p>
                </div>
              </div>
              <div className="mb-10 flex items-center md:justify-center md:mr-6">
                <img src={Email} alt="" className="mr-5 w-4" />
                <p className="text-sm font-opensans font-normal text-[#C5C5C5]">support@tomedes.com </p>
              </div>

              <div className="mb-10 flex items-center md:justify-start ">
                <div className="w-9/12 flex items-center justify-between">
                  <a className="p-2 social_media_icon_parent group" href="https://www.facebook.com/Tomedes.translation/" target="_blank">
                    <FontAwesomeIcon className=" text-[26px] social_media_icon_hover inline-block cursor-pointer group-hover:text-lightBlue text-white" icon={faFacebookF} />
                  </a>
                  <a href="https://twitter.com/Tomedes" target="_blank" className="p-2 social_media_icon_parent group">
                    <FontAwesomeIcon className=" text-[26px] social_media_icon_hover inline-block cursor-pointer group-hover:text-lightBlue text-white" icon={faTwitter} />
                  </a>
                  <a href="https://www.linkedin.com/company/tomedes.com" target="_blank" className="p-2 social_media_icon_parent group">
                    <FontAwesomeIcon className=" text-[26px] social_media_icon_hover inline-block cursor-pointer group-hover:text-lightBlue text-white" icon={faLinkedinIn} />
                  </a>
                  <a href="https://www.youtube.com/channel/UC9k2By4XhHEjI6usn0nJfNw" target="_blank" className="p-2 social_media_icon_parent group">
                    <FontAwesomeIcon className=" text-[26px] social_media_icon_hover inline-block cursor-pointer group-hover:text-lightBlue text-white" icon={faYoutube} />
                  </a>
                   <a href="https://www.instagram.com/tomedes_translation/" target="_blank" className="p-2 social_media_icon_parent group">
                    <FontAwesomeIcon className=" text-[26px] social_media_icon_hover inline-block cursor-pointer group-hover:text-lightBlue text-white" icon={faInstagram} />
                  </a>
                  <a href="https://www.tiktok.com/@tomedestranslation" target="_blank" className="p-2 social_media_icon_parent group">
                    <FontAwesomeIcon className=" text-[26px] social_media_icon_hover inline-block cursor-pointer group-hover:text-lightBlue text-white" icon={faTiktok} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
