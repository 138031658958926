import * as React from "react";

function LogoSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={184}
      height={37.629}
      {...props}
    >
      <defs>
        <style>{".prefix__b{fill:#fff}"}</style>
      </defs>
      <path
        d="M0 0h167.751v28.806H0z"
        style={{
          isolation: "isolate",
        }}
        fill="#042c7f"
      />
      <path d="M33.487 14.764h-1.035v-6.3H25.34v27.319h3.331v1.035h-12.6v-1.035h3.331V8.462h-7.16v6.075h-1.035v-7.2h22.325l-.045 7.427zM54.192 26.106a10.577 10.577 0 01-3.151 7.652 10.442 10.442 0 01-15.123 0 10.6 10.6 0 01-3.151-7.7 10.753 10.753 0 013.151-7.7 10.393 10.393 0 0115.123-.045 11.152 11.152 0 013.151 7.793zm-6.841 5.941V20.12a3.744 3.744 0 00-1.17-2.746 3.918 3.918 0 00-6.614 2.746v11.928a3.7 3.7 0 001.125 2.746 3.806 3.806 0 005.491 0 3.818 3.818 0 001.167-2.746zM89.614 30.697a6.578 6.578 0 01-1.485 4.456 5.01 5.01 0 01-3.961 1.71 5.556 5.556 0 01-3.871-1.53 5.074 5.074 0 01-1.665-3.916V19.265a3.069 3.069 0 00-.54-1.71 1.6 1.6 0 00-1.4-.81c-.9 0-1.575.54-2.025 1.665a8.556 8.556 0 00-.72 3.106v15.348h-6.031v-20.48a4.568 4.568 0 00-3.241 1.35 4.182 4.182 0 00-1.44 3.061v16.024h-6.028V16.384h-1.26v-1.08h.72a16.088 16.088 0 011.665.09 8.168 8.168 0 011.53.36 3.642 3.642 0 011.53.945 6.692 6.692 0 011.215 1.755 4.851 4.851 0 012.16-2.3 6.486 6.486 0 013.2-.81 6.612 6.612 0 013.2.81 5.175 5.175 0 012.205 2.3 4.777 4.777 0 014.816-3.106 6.216 6.216 0 014.5 1.8 6.109 6.109 0 011.89 4.591v14.044a4.331 4.331 0 002.791-1.44 4.4 4.4 0 001.125-2.926v-.675h1.125v-.045z" />
      <path d="M109.148 25.656H95.42v6.211a4 4 0 003.916 3.916 9.968 9.968 0 004.861-1.305 9.4 9.4 0 003.556-3.466l.99.54a10.557 10.557 0 01-3.961 3.871 11.032 11.032 0 01-5.4 1.44 10.175 10.175 0 01-7.517-3.2 10.813 10.813 0 010-15.168 10.077 10.077 0 017.472-3.2c3.2 0 5.581.99 7.2 2.926a12.042 12.042 0 012.611 7.435zm-5.851-1.035v-4.366a3.743 3.743 0 00-1.17-2.746 3.7 3.7 0 00-2.746-1.125 3.919 3.919 0 00-2.791 1.17 3.718 3.718 0 00-1.17 2.7v4.321h7.877v.045zM136.154 31.372a5.074 5.074 0 01-1.665 3.916 5.6 5.6 0 01-3.871 1.575 5.208 5.208 0 01-3.691-1.485 10.147 10.147 0 01-5.266 1.485 10.681 10.681 0 01-10.577-10.757 10.36 10.36 0 013.151-7.652 10.051 10.051 0 017.382-3.151 10.773 10.773 0 013.6.675v-2.025a6.807 6.807 0 011.62-5 4.454 4.454 0 012.025-1.305 10.512 10.512 0 012.566-.315h1.125v1.08h-1.35v27.28a4.331 4.331 0 002.746-1.44 4.4 4.4 0 001.125-2.926v-.675h1.08v.72zm-9.947 3.151a5.386 5.386 0 01-1.035-3.151V17.104a9.748 9.748 0 00-3.646-.72 3.743 3.743 0 00-2.746 1.17 3.673 3.673 0 00-1.125 2.7v11.613a4 4 0 003.916 3.916 8.867 8.867 0 004.636-1.26z" />
      <path d="M155.688 25.656H141.96v6.211a4 4 0 003.916 3.916 9.969 9.969 0 004.861-1.305 9.4 9.4 0 003.556-3.466l.99.54a10.558 10.558 0 01-3.961 3.871 11.033 11.033 0 01-5.4 1.44 10.175 10.175 0 01-7.517-3.2 10.813 10.813 0 010-15.168 10.076 10.076 0 017.472-3.2c3.2 0 5.581.99 7.2 2.926a12.617 12.617 0 012.611 7.435zm-5.851-1.035v-4.366a3.743 3.743 0 00-1.17-2.746 3.7 3.7 0 00-2.746-1.125 3.92 3.92 0 00-2.791 1.17 3.719 3.719 0 00-1.17 2.7v4.321h7.877v.045zM176.393 30.787a5.274 5.274 0 01-2.656 4.411 12.1 12.1 0 01-6.8 1.8 10.53 10.53 0 01-6.661-2.115 6.307 6.307 0 01-2.7-5.176h1.08a5.144 5.144 0 002.565 4.5 10.346 10.346 0 005.806 1.71c.27 0 .63-.045 1.08-.09a16.2 16.2 0 001.89-.315 4.187 4.187 0 002.026-.9 2.067 2.067 0 00.81-1.665q0-1.62-4.321-3.376c-.135-.045-.675-.27-1.62-.63s-1.71-.675-2.3-.9a18.576 18.576 0 01-2.3-1.17 12.856 12.856 0 01-2.25-1.53 8.877 8.877 0 01-1.485-1.845 4.468 4.468 0 01-.63-2.341 4.721 4.721 0 012.746-4.231 13.615 13.615 0 016.841-1.575 9.974 9.974 0 014.546 1.125c1.53.72 2.3 1.71 2.3 2.926a2.378 2.378 0 01-.72 1.755 2.342 2.342 0 01-1.8.72 2.309 2.309 0 01-1.8-.765 2.716 2.716 0 01-.675-1.8 2.212 2.212 0 011.53-2.205 9.806 9.806 0 00-3.691-.72c-.36 0-.72.045-1.17.09a12.717 12.717 0 00-1.8.315 4.074 4.074 0 00-1.89.855 1.941 1.941 0 00-.72 1.575 1.567 1.567 0 00.54 1.125 3.427 3.427 0 001.17.81c.855.36 2.206.99 4.051 1.8 1.89.855 3.331 1.485 4.321 1.935a9.3 9.3 0 013.376 2.431 5.422 5.422 0 011.311 3.466zM183.144 31.957a2.786 2.786 0 01.855 2.025 2.689 2.689 0 01-.855 2.025 2.881 2.881 0 01-4.906-2.024 2.689 2.689 0 01.855-2.025 2.787 2.787 0 012.026-.855 3.161 3.161 0 012.025.854z" />
      <g>
        <path
          className="prefix__b"
          d="M32.992 15.394h-1.035v-6.3h-7.112v27.319h3.331v1.035h-12.6v-1.035h3.331V9.092h-7.16v6.075h-1.035v-7.2h22.325l-.045 7.427zM53.697 26.736a10.577 10.577 0 01-3.151 7.652 10.442 10.442 0 01-15.123 0 10.6 10.6 0 01-3.151-7.7 10.753 10.753 0 013.151-7.7 10.442 10.442 0 0115.123 0 10.969 10.969 0 013.151 7.748zm-6.841 5.941V20.754a3.744 3.744 0 00-1.17-2.746 3.918 3.918 0 00-6.614 2.746v11.924a3.7 3.7 0 001.125 2.746 3.916 3.916 0 006.661-2.746zM89.119 31.327a6.579 6.579 0 01-1.485 4.456 5.01 5.01 0 01-3.961 1.71 5.556 5.556 0 01-3.871-1.53 5.074 5.074 0 01-1.665-3.916V19.895a3.069 3.069 0 00-.54-1.71 1.6 1.6 0 00-1.4-.81c-.9 0-1.575.54-2.025 1.665a8.556 8.556 0 00-.72 3.106v15.348h-5.941V17.059a4.569 4.569 0 00-3.241 1.35 4.182 4.182 0 00-1.44 3.061v16.024h-6.028V17.059h-1.26v-1.08h.72a16.086 16.086 0 011.665.09 8.164 8.164 0 011.53.36 3.642 3.642 0 011.53.945 6.69 6.69 0 011.215 1.755 4.851 4.851 0 012.16-2.3 6.487 6.487 0 013.2-.81 6.612 6.612 0 013.2.81 5.175 5.175 0 012.205 2.3 4.777 4.777 0 014.816-3.106 6.215 6.215 0 014.5 1.8 6.109 6.109 0 011.89 4.591v14.044a4.331 4.331 0 002.791-1.44 4.4 4.4 0 001.125-2.926v-.675h1.035v-.09z"
        />
        <path
          className="prefix__b"
          d="M108.653 26.286H94.97v6.211a4 4 0 003.916 3.916 9.968 9.968 0 004.861-1.305 9.4 9.4 0 003.556-3.466l.99.54a10.558 10.558 0 01-3.961 3.871 11.033 11.033 0 01-5.4 1.44 10.175 10.175 0 01-7.517-3.2 10.813 10.813 0 010-15.168 10.077 10.077 0 017.472-3.2c3.2 0 5.581.99 7.2 2.926a12.3 12.3 0 012.566 7.435zm-5.851-1.035v-4.317a3.744 3.744 0 00-1.17-2.746 3.7 3.7 0 00-2.746-1.125 3.919 3.919 0 00-2.791 1.17 3.718 3.718 0 00-1.17 2.7v4.321zM135.66 32.002a5.075 5.075 0 01-1.665 3.916 5.6 5.6 0 01-3.871 1.575 5.207 5.207 0 01-3.691-1.485 10.147 10.147 0 01-5.266 1.485 10.681 10.681 0 01-10.578-10.757 10.36 10.36 0 013.151-7.652 10.051 10.051 0 017.382-3.151 10.772 10.772 0 013.6.675v-2.025a6.807 6.807 0 011.62-5 4.455 4.455 0 012.025-1.305 10.512 10.512 0 012.566-.315h1.125v1.08h-1.35v27.28a4.332 4.332 0 002.746-1.44 4.4 4.4 0 001.125-2.926v-.675h1.08v.72zm-9.947 3.151a5.386 5.386 0 01-1.035-3.151V17.734a9.749 9.749 0 00-3.646-.72 3.744 3.744 0 00-2.746 1.17 3.673 3.673 0 00-1.125 2.7v11.613a4 4 0 003.916 3.916 8.865 8.865 0 004.635-1.26z"
        />
        <path
          className="prefix__b"
          d="M155.193 26.286h-13.727v6.211a4 4 0 003.916 3.916 9.968 9.968 0 004.861-1.305 9.4 9.4 0 003.556-3.466l.99.54a10.557 10.557 0 01-3.961 3.871 11.032 11.032 0 01-5.4 1.44 10.175 10.175 0 01-7.517-3.2 10.813 10.813 0 010-15.168 10.077 10.077 0 017.472-3.2c3.2 0 5.581.99 7.2 2.926a12.616 12.616 0 012.61 7.435zm-5.851-1.035v-4.317a3.744 3.744 0 00-1.17-2.746 3.7 3.7 0 00-2.746-1.125 3.919 3.919 0 00-2.791 1.17 3.718 3.718 0 00-1.17 2.7v4.321zM175.898 31.417a5.274 5.274 0 01-2.656 4.411 12.1 12.1 0 01-6.8 1.8 10.531 10.531 0 01-6.662-2.115 6.307 6.307 0 01-2.7-5.176h1.08a5.144 5.144 0 002.566 4.5 10.346 10.346 0 005.806 1.71c.27 0 .63-.045 1.08-.09a16.2 16.2 0 001.89-.315 4.187 4.187 0 002.025-.9 2.066 2.066 0 00.81-1.665q0-1.62-4.321-3.376c-.135-.045-.675-.27-1.62-.63s-1.71-.675-2.3-.9a18.567 18.567 0 01-2.3-1.17 12.868 12.868 0 01-2.251-1.53 8.888 8.888 0 01-1.485-1.845 4.47 4.47 0 01-.63-2.341 4.721 4.721 0 012.746-4.231 13.614 13.614 0 016.842-1.575 9.974 9.974 0 014.546 1.125c1.53.72 2.3 1.71 2.3 2.926a2.378 2.378 0 01-.72 1.755 2.341 2.341 0 01-1.8.72 2.31 2.31 0 01-1.8-.765 2.716 2.716 0 01-.675-1.8 2.212 2.212 0 011.53-2.205 9.806 9.806 0 00-3.691-.72c-.36 0-.72.045-1.17.09a12.71 12.71 0 00-1.8.315 4.074 4.074 0 00-1.89.855 1.941 1.941 0 00-.72 1.575 1.567 1.567 0 00.54 1.125 3.426 3.426 0 001.17.81c.855.36 2.206.99 4.051 1.8 1.89.855 3.331 1.485 4.321 1.935a9.294 9.294 0 013.376 2.431 5.422 5.422 0 011.312 3.466zM182.649 32.632a2.786 2.786 0 01.855 2.025 2.688 2.688 0 01-.855 2.025 2.881 2.881 0 01-4.906-2.025 2.689 2.689 0 01.855-2.025 2.786 2.786 0 012.025-.855 2.758 2.758 0 012.026.855z"
        />
      </g>
    </svg>
  );
}

export default LogoSvg;
